import styles from "./games-card.module.scss";
import { RiCloseCircleFill } from 'react-icons/ri';
import Amadi from "../../assets/images/AMADI.svg"
import Kenga from "../../assets/images/KENGA.svg"
import { getInitials } from "../LiveGameSessionsContent";


const UserProfile = ({avatar, gameImg, style, initials})  => {
    

    // const getInitials = (str) => {
    //     if (str.length >= 2) {
    //       const firstLetter = str[0].toUpperCase();
    //       const secondLetter = str[1].toUpperCase();
    //       return `${firstLetter}${secondLetter}`;
    //     } else {
    //       return "";
    //     }
    // };


    return (
        <div className={styles["userProfileContainer"]}>
            <div className={styles["userProfileImg"]}>
                {avatar === "" ? (
                    <p>{getInitials(initials)}</p>
                ) : <img src={avatar}  alt="user profile dp"  />}
            </div>

            <div className={styles["userGameImg"]} style={style}>
                <img src={gameImg}  alt="user profile dp"  />
            </div>
        </div>
    )
}


const GameStats = ({type, value})  => {
    return (
        <div className={styles["gameStatsContainer"]}>
            <p className={styles["gameStatsType"]}>{type}</p>

            <p className={styles["gameStatsValue"]}>{value}</p>

        </div>
    )
}



const GamesStatModal = ({onClose, liveGame}) => {
    const style1 = {
        right: "-50px"
    }

    const style2 = {
        left: "-40px"
    }



    const { wager, users, game } = liveGame;

    return (
        <div className={styles["modalContainer"]} >
            
            <div className={styles["modalContainerContent"]} >
                <p className={styles["closeIcon"]} onClick={onClose}><RiCloseCircleFill /></p>

                <div className={styles["modalHeader"]}>
                    <h2>Game Stat</h2>
                    <p>{game?.id}</p>
                </div>


                
                    {users?.length === 1 ? (
                         <div className={styles["singleVersus"]}>
                            <UserProfile 
                                avatar={users[0]?.avatar}
                                gameImg={Amadi}
                                style={style1}
                                initials={users[0]?.username}
                            />
                        </div>

                    ) : users?.length === 1 ? (
                        <div className={styles["versusContent"]}>

                            <UserProfile 
                                avatar={users[0]?.avatar}
                                gameImg={Amadi}
                                style={style1}
                                initials={users[0]?.username}
                            />

                            <h2>Vs</h2>

                            <UserProfile       
                                avatar={users[0]?.avatar}
                                gameImg={Kenga}
                                style={style2}
                                initials={users[1]?.username}
                            />
                            </div>
                    ) : (
                        <p>No users available</p>
                    )}


               


                <div className={styles["gameStatsContent"]}>
                    <GameStats   
                        type="Gameplay"
                        value="Classic Fight"
                    />

                    <GameStats   
                        type="Arena"
                        value="Ijo Fight Center"
                    />
                    <GameStats   
                        type="Rounds"
                        value="3"
                    />

                    <GameStats   
                        type="Duration"
                        value="100 Secs"
                    />
                    <GameStats   
                        type="Stakes"
                        value={`${wager?.amount} ${wager?.type}`}
                    />
                </div>


            </div>
        </div>
    )
}

export default GamesStatModal