import React from 'react'
import styles from "./add-blog.module.scss"
import { useState,  useContext } from 'react'
import BlogsContext from '@/context/Blogs/BlogsContext'
import { UploadImage } from '@/utils/helpers'
import { useNavigate } from 'react-router-dom'
import AlertContext from '@/context/Alert/AlertContext'
import RichTextEditor from '@/components/RichTextEditor'
import Loader from '@/components/Loader'


const AddBlog = () => {

    const { loading, createNewPost } = useContext(BlogsContext)
    const [image, setImage] = useState(null)

    // console.log(image)

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        thumbnailUrl: "",
        content: "",
        communityId: ""
    })
    
    //Onchange 
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id] : e.target.value
        }
        ))
    }

    const { setErrorAlert } = useContext(AlertContext)

    const { title, description, thumbnailUrl, content  } = formData;

    const navigate = useNavigate()

    const [load, setLoad]  = useState(false)

    //Method to submit form
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoad(true);
    
        try {
            if (!title || !description || !content) {
                setErrorAlert("Please, fill the form and try again");
                return;
            }
    
            if (!image) {
                setErrorAlert("Please, upload image");
                return;
            }
    
            const res = await UploadImage(image);
            if (res?.url) {
                const blogDetails = {
                    ...formData,
                    thumbnailUrl: res.url,
                };
                await createNewPost(blogDetails);
                navigate("/dashboard/blogs");
            }
        } catch (error) {
            console.error("An error occurred while creating the post:", error);
            // Handle additional error logic if needed
        } finally {
            setLoad(false);
        }
    };
    

  return (
    <div className={ styles["add__blog"] }>
        <div className={ styles["add__blog__header"] }>
            <h2>Create Post</h2>
            <p>Create a new blog post today and excite your audience...!</p>
        </div>
        <form onSubmit={ handleSubmit }>
           <div>
            <label htmlFor="title">Post Title *</label>
                <input
                    type="text"
                    placeholder='Post title'
                    value={ title }
                    id='title'
                    onChange={ onChange } />
            </div>
            <div>
            <label htmlFor="title">Post Description *</label>
                <input
                    type="text"
                    placeholder='Post description'
                    value={ description }
                    id='description'
                    onChange={ onChange } />
            </div>
            {/* <div>
                <label htmlFor="title">Post Content *</label>
                    <textarea 
                        id="content" 
                        placeholder='Post content....'
                        value={ content }
                        onChange={ onChange }
                        ></textarea>
                </div>  */}

                <RichTextEditor
                    onChange={ (val) => setFormData({ ...formData, content: val })  }
                    value={content}
                    placeholder={`Thinking about anything? Well, put it out here.`}
                />

            <div>
                <input 
                    type="file"
                    value={ thumbnailUrl.name }
                    accept="image/*"
                    id='thumbnailUrl'
                    onChange={ (e) => setImage(e.target.files[0]) }
                />
            </div>
            <button type='submit'>{ load || loading ? (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                    <Loader />
                    Uploading your post!
                </div>
            ) : "Create Post" }</button>
        </form>
    </div>
  )
}

export default AddBlog