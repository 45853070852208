import styles from "./leader.module.scss";
import User from "../../assets/images/pp.svg"
import moment from "moment";

const ActivitiesContent = ({ activity, index, isSelected, onCheckboxChange }) => {
  const { user,  createdAt, action } = activity;
  

  return (
    <div className={styles["leader"]}>
      <div className={styles["leader__user"]}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onCheckboxChange(index)}
        />
        {        
          user?.avatar ?  <div className={styles["leader__user__avatar"]}>
            <img src={user?.avatar} alt={user} />
          </div> :  <img src={User} alt={user} />
        }
        <p>{user?.fullName}</p>
      </div>
      <div>{user?.email}</div>
      <div>{user?.fullName}</div>
      <div>{moment(createdAt).format("Do MMMM, YYYY")}</div>
      <div>{action} </div>
    </div>
  );
};

export default ActivitiesContent;
