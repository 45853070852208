import styles from './games.module.scss'
import StatsCard from '@/components/StatsCard'
import {  HiVideoCamera } from 'react-icons/hi2'
import { FaGamepad } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import LiveGameSessions from './LiveGameSessions'
import { IoArrowBack } from "react-icons/io5";
import Activities from './Activities'
import LeaderBoardGame from './LeaderBoard'
import { useContext, useEffect } from 'react'
import GameContext from '@/context/Games/GameContext'
import Spinner from '@/components/Spinner'



const GameDetails = () => {

    

  const params = useParams();
  const id = params.id;

  const {
    fetchSingleGames,
    singleGameData,
    singleGameLoading,
    fetchSingleGameLiveSession,
    fetchActivities
  }  = useContext(GameContext)


  useEffect(() => {
    
    fetchSingleGames(id)
    fetchSingleGameLiveSession(id)
    fetchActivities()
    
  }, [])

  const navigate = useNavigate();

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/dashboard/overview'); // Fallback route
    }
  };

  

  return (
    <div className={styles['games__container']}>
      <div className={styles['games__container__back']} onClick={handleBack}>
        <IoArrowBack size="1.2rem" fill="#999999" />
        <p>Go back</p>
      </div>

      { singleGameLoading ? <Spinner  /> : (
        <>
          <header>
            <h1>{singleGameData?.game?.name}s</h1>
            <p>Manage your games etc...</p>
          </header>


          <div className={styles["games__container__card"]}>
            <StatsCard 
              title='Total Gaming Sessions' 
              bgColor="#EAF3FF"
              value={ singleGameData?.totalSessions } 
              icon={ <FaGamepad size="1.2rem" fill='#1E5EBB'/> } 
            />
          
            <StatsCard 
              title='Live Channels' 
              bgColor="#FCF3FF"
              value={singleGameData?.totalSessions } 
              icon={ <HiVideoCamera size="1.2rem" fill='#A55BBF'/> } 
            />


            <StatsCard 
              title='Gamers Currently Playing' 
              bgColor="#EAF3FF"
              value={ singleGameData?.currentGamers} 
              icon={ <FaGamepad size="1.2rem"  fill='#0EB281'/> } 
            />

            <StatsCard 
              title='Total Number of Gamers' 
              bgColor="#E8FFF8"
              value={singleGameData?.totalGamers } 
              icon={ <FaGamepad size="1.2rem" fill='#0EB281'/> } 
            />
          </div>


          <LeaderBoardGame  />

          <Activities  />

          <LiveGameSessions  />

        </>
      )
        
      }


      
      {/* {userProducts?.length === 0 ? (
        <div className={styles.emptyContainer}>  
          <p> There are no products yet</p>
        </div>
      ) : (
        <section className={styles['flex__group']}>
          {userProducts?.map((data, index) => (
            <ProductBox key={index} {...data} />
          ))}
        </section>
      )} */}



    </div>
  )
}

export default GameDetails
