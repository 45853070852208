import { useContext } from 'react';
import styles from './games.module.scss'
import Leader from '@/components/Leader'
import GameContext from '@/context/Games/GameContext';




const LeaderBoardGame = () => {

  const {singleGameData }  = useContext(GameContext)



  return (
    <>

      <div className={styles["games__container__available"]}>
        <h2>Leaderboard</h2>

        <div className={styles["leaderboard"]}>
            <>
              <div className={styles["leaderboard__container"]}>
                <div className={styles["leaderboard__rank"]}>
                  <div className={styles["leaderboard__rank__header"]}>
                    <div className={styles["leaderboard__rank__header--item1"]}>
                      S / N
                    </div>
                    <div className={styles["leaderboard__rank__header--item2"]}>
                      Username
                    </div>
                    <div className={styles["leaderboard__rank__header--item3"]}>
                      Points gained
                    </div>
                    <div className={styles["leaderboard__rank__header--item3"]}>
                      Last reward
                    </div>
                  </div>
                </div>
                {singleGameData?.leaderboard &&
                  singleGameData?.leaderboard?.length > 0 &&
                  singleGameData?.leaderboard?.map((leader, index=0) => (
                    <Leader 
                      key={leader?.user?.id} 
                      leader={leader} 
                      index={index + 1}
                    />
                  ))}
              </div>
            </>
        </div>
      </div>
      
  


    </>
  )
}

export default LeaderBoardGame
