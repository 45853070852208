// import Input from '@/components/Input'
import styles from './games.module.scss'
import StatsCard from '@/components/StatsCard'
import {  HiGift, HiVideoCamera } from 'react-icons/hi2'
import { FaGamepad } from 'react-icons/fa'
import GamesCard from '@/components/GamesCard'
import Ija from "../../assets/images/ija.png"
import LiveGameSessions from '../GamesDetails/LiveGameSessions'
import { useContext, useEffect } from 'react'
import GameContext from '@/context/Games/GameContext'
import Spinner from '@/components/Spinner'

const Games = () => {

  const {fetchAllGames, allGames, loading, fetchLiveSessions, gameStats}  = useContext(GameContext)
  useEffect(() => {
    fetchAllGames()
    fetchLiveSessions()
  }, [])

  return (
    <div className={styles['games__container']}>
      <header>
        <h1>Games</h1>
        <p>Manage your games etc...</p>
      </header>
      
      {loading ? <Spinner  /> : (
        <>
        
          <div className={styles["games__container__card"]}>
            <StatsCard 
              title='Live Channels' 
              bgColor="#FCF3FF"
              value={ gameStats?.liveChannels} 
              icon={ <HiVideoCamera size="1.2rem" fill='#A55BBF'/> } 
            />
            <StatsCard 
              title='Total Number of Games' 
              bgColor="#E8FFF8"
              value={ gameStats?.games?.length } 
              icon={ <FaGamepad size="1.2rem" fill='#0EB281'/> } 
            />
            <StatsCard 
              title='Total Gaming Sessions' 
              bgColor="#EAF3FF"
              value={ gameStats?.totalSessions } 
              icon={ <FaGamepad size="1.2rem" fill='#1E5EBB'/> } 
            />
            <StatsCard 
              title='Points Consumed by Users' 
              bgColor="rgba(34, 187, 51, 0.05)"
              value={ gameStats?.totalPointsConsumed  } 
              icon={ <HiGift  size="1.2rem" fill='#22BB33'/> } 
            />
          </div>



          <div className={styles["games__container__available"]}>
            <h2>Games Available</h2>

            <div className={styles["games__container__list"]}>
              {!loading && allGames?.length > 0 ? (
                allGames?.map((game, index) => (
                  <GamesCard  
                    key={index}
                    games={game}
                  />
                ))
              ) : (
                <p>There are no games available at the moment</p>
              )}

            </div>
          </div>

        </>
        
      )}







      <LiveGameSessions  />

      
      {/* {userProducts?.length === 0 ? (
        <div className={styles.emptyContainer}>  
          <p> There are no products yet</p>
        </div>
      ) : (
        <section className={styles['flex__group']}>
          {userProducts?.map((data, index) => (
            <ProductBox key={index} {...data} />
          ))}
        </section>
      )} */}



    </div>
  )
}

export default Games
